.hero-container {
    position: relative;
    z-index: 1;
}

.footer {
    position: relative;
    z-index: 2;
}
.nav-links {
    display: flex;
    color: #000000;
    align-items: center;
    text-decoration: none;
}
.footer .nav-links {
    color: #000000;
}