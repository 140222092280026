/* Main navigation bar styles */
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

/* Container within the navbar */
.navbar-container {
  display: flex;
  justify-content: space-between; /* Adjusted for space between items */
  align-items: center;
  height: 80px;
  width: 100%; /* Adjusted for full width */
  padding: 0 20px; /* Added padding */
  max-width: 1500px;
}

/* Navbar logo styling */
.navbar-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

/* Navigation menu items */
.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

/* Individual navigation item */
.nav-item {
  height: 80px;
}

/* Links within the navigation menu */
.nav-links {
  color: #ffffff !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

/* Hover effect for the navigation links */
.nav-links:hover {
  border-bottom: 4px solid #ffffff;
  transition: all 0.2s ease-out;
}

/* Mobile menu icon - displayed on small screens */
.menu-icon {
  color: #ffffff;
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
}

/* Dropdown menu, hidden by default */
.mobile-menu {
  display: none;
}

/* Responsive design for smaller screens */
@media screen and (max-width: 960px) {
  /* Adjust the container padding on smaller screens */
  .navbar-container {
      justify-content: space-between; /* Centering the logo is removed */
  }

  /* Toggle display of the menu icon */
  .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      z-index: 9999;
  }

  /* Hide the regular navigation menu */
  .nav-menu {
      display: none;
  }

  /* Styles for the dropdown menu when active */
  .mobile-menu.active {
      display: block;
      position: absolute;
      top: 80px;
      width: 100%;
      background-color: #242222;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }

  /* Dropdown menu links styling */
  .mobile-menu-item {
      display: block;
      text-align: center;
      padding: 15px;
      color: #fff;
      text-decoration: none;
      border-bottom: 1px solid #333; /* Separates the items visually */
  }

  /* Styling for the logo in the mobile view */
  .navbar-logo {
      margin-left: 20px;
      margin-right: auto; /* Logo stays on the left */
  }
}
