.footer {
    background-color: #ffffff;
    color: #000000;
    padding: 20px 0;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    flex: 1;
    padding: 0 20px;
}

.footer-section h1 span,
.footer-section h2 {
    color: #000000;
    text-align: center;
}
.socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}

.footer-section .logo-text span {
    color: #000000;
}

.footer-section ul {
    list-style: none;
    color: #000000 ;
    padding: 0;
    text-align: center;
    justify-content: center;
}

.footer-section ul li a {
    color: #000000; /* black */
    text-decoration: none;
    transition: all 0.5s ease;
    align-items: center;
    justify-content: center;
}
.footer-section ul li a:hover {
    color: #000000;
}

.footer-section p {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    color: #000000;
}
.footer-section ul a {
    color: #000000;
    text-decoration: none;
}
.footer-nav-links {
    display: flex;
    color: #000000 !important;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    justify-content: center;
}

.footer-bottom {
    background-color: #eee;
    color: #000000;
    text-align: center;
    padding: 10px;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .footer-content {
        flex-direction: column;
    }

    .footer-section {
        margin-bottom: 20px;
    }

    .footer-bottom {
        font-size: 12px;
    }
}