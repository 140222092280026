/* Base styles for the hero container */
.hero-container {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
}

/* Style for background video */
.hero-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

/* Overlay styles */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 0;
}

/* Content in the hero section */
.hero-content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Align vertically */
    align-items: center; /* Align horizontally */
    text-align: center; /* Centers any text content */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
}

/* Container for logo and buttons */
.logo-and-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; /* Space between logo and buttons */
}

/* Responsive logo that adjusts size based on viewport width */
.logo-and-buttons img {
    max-width: 60%; /* Scales based on the width of its container */
    height: auto; /* Keeps the aspect ratio intact */
    margin: auto; /* Center the image within its container */
}

/* Button styles */
.hero-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Adds space between logo and buttons */
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
    .logo-and-buttons {
        padding-top: 25%; /* Increase padding-top to maintain aspect ratio on smaller screens */
    }
    
    .logo-and-buttons img {
        width: 50%; /* You can adjust this as necessary */
    }

    .hero-btns {
        flex-direction: column;
        align-items: center;
    }
    
    .hero-btns .btns {
        width: 100%; /* Full width for smaller screens */
        margin-bottom: 10px; /* Space between buttons */
    }
}

@media screen and (max-width: 480px) {
    .logo-and-buttons img {
        width: 70%; /* Larger percentage for smaller screens */
    }
}
