.email-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  color: rgb(0, 0, 0);
  transform: translate(-50%, -50%);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(0, 0, 0);
  z-index: 10;
}

.email-popup input {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.email-popup button {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  border: none;
  background: #333;
  color: white;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #333;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 10px;
  border-radius: 50%;
}
.email-popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 100%; /* Adjust as needed */
  height: 100%;
  box-sizing: border-box;
  max-width: 500px; /* Adjust as needed */
}
.email-popup-heading {
  font-size: 24px;
  margin-bottom: 20px;
}